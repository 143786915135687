<template>
    <div>
        <canvas :id="chartId" ref="canvas"></canvas>
    </div>
</template>

<script>

    import Chart from 'chart.js/auto'

    export default {
        name: 'ChartView',
        props: ['id', 'data'],
        data() {
            return {
                chartId: this.id,
                chart: null,
            }
        },
        mounted() {
            const ctx = this.$refs.canvas;
            this.chart = new Chart(ctx, this.data);
        },
    }

</script>

