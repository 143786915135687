const localeDate = (shift) => {
    const date = new Date()
    date.setDate(date.getDate() + shift)
    return date.toLocaleDateString(
        'en-US',
        { month: 'short', day: 'numeric' }
    )
}

const getRandomIntInclusive = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const generateData = (qty, color) => {
    const labels = []
    const dataset = {
        data: [],
        backgroundColor: color,
        borderColor: '#888888',
        borderWidth: 1,
    }
    for (let i = 0; i < qty; i++) {
        dataset.data[i] = getRandomIntInclusive(0, 20);
        labels[i] = localeDate(i - qty + 1)
    }
    return {
        labels,
        datasets: [dataset]
    }
}

const randomChartsData = (qty, color) => {
    return {
        type: 'bar',
        data: generateData(qty, color),
        options: {
            scales: {
                y: {
                    beginAtZero: true
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        }
    }
}

const chartsData = (data, color) => {
    return {
        type: 'bar',
        data: {
            labels: Object.keys(data),
            datasets: [{
                data: Object.values(data),
                backgroundColor: color,
                borderColor: '#888888',
                borderWidth: 1,
            }]
        },
        options: {
            scales: {
                y: {
                    beginAtZero: true
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        }
    }
}

export {randomChartsData, chartsData}
